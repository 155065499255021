import React, { isValidElement, ReactNode, ReactElement } from "react";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";

interface TemplateProps {
  children: ReactNode;
}

const Template: React.FC<TemplateProps> = (props) => {
  const children = React.Children.toArray(props.children).filter(
    isValidElement
  ) as ReactElement[];

  const renderChildrenInDropdown = (child: ReactElement | undefined) => {
    if (!child?.props?.children) return null;

    // If children is an array, map through them
    if (Array.isArray(child.props.children)) {
      return child.props.children.map((item: ReactElement, index: number) => (
        <DropdownMenuItem key={index} className="cursor-pointer">
          {item}
        </DropdownMenuItem>
      ));
    }

    // If children is a single element
    return (
      <DropdownMenuItem className="cursor-pointer">
        {child.props.children}
      </DropdownMenuItem>
    );
  };

  return (
    <div className="relative flex h-12 items-center border-b bg-muted px-4 md:px-6 lg:px-8 z-50">

      <div className="flex items-center gap-2">
        {children.find((child) => child.type === Brand)?.props.children}
        <Separator orientation="vertical" className="h-6 bg-gray-200" />
        <div className="flex gap-3">
          {children.find((child) => child.type === Links)?.props.children}
        </div>
      </div>
      <div className="flex-1" />

      <div className="md:flex gap-3">
        {children.find((child) => child.type === UserProfile)?.props.children}
      </div>
    </div>
  );
};

const Brand: React.FC = () => null;

const Links: React.FC = () => null;

const UserProfile: React.FC = () => null;

interface NavbarComponent extends React.FC<TemplateProps> {
  Brand: typeof Brand;
  Links: typeof Links;
  UserProfile: typeof UserProfile;
}

export const Navbar: NavbarComponent = Object.assign(Template, {
  Brand,
  Links,
  UserProfile,
});

export default Navbar;