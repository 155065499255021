import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Input } from '../ui/input';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Database, LockKeyholeOpenIcon, LogIn, Search, Server, Shield, User, Users, X } from 'lucide-react';
import { ScrollArea } from '../ui/scroll-area';
import { RiAdminFill } from "react-icons/ri"
import { Separator } from '../ui/separator';

// Define a type for the service
type Service = {
    display: string;
    station_name: string;
    description: string;
    link: string;
    icon: string;
};

const icons: { [key: string]: React.ReactElement } = {
    "iam_station_icon": <Shield className="h-6 w-6 group-hover:text-primary" />,
    "iam_user_icon": <User className="h-6 w-6 group-hover:text-primary" />,
    "iam_group_icon": <Users className="h-6 w-6 group-hover:text-primary" />,
    "iam_role_icon": <RiAdminFill className="h-6 w-6 group-hover:text-primary" />,
    "iam_policy_icon": <LockKeyholeOpenIcon className="h-6 w-6 group-hover:text-primary" />,
    "iam_sso_icon": <LogIn className="h-6 w-6 group-hover:text-primary" />,
    "unknown": <Server className="h-6 w-6 group-hover:text-primary" />,
}

// Mock list of services
const services: { [key: string]: Service } = {
    "iam": {
        "display": "IAM",
        "station_name": "IAM",
        "description": "IAM Station",
        "link": "/iam",
        "icon": "iam_station_icon"
    },
    "user": {
        "display": "User",
        "station_name": "IAM",
        "description": "Manager IAM users",
        "link": "/iam/users/list",
        "icon": "iam_user_icon"
    },
    "group": {
        "display": "Group",
        "station_name": "IAM",
        "description": "Manager IAM groups",
        "link": "/iam/groups/list",
        "icon": "iam_group_icon"
    },
    "role": {
        "display": "Role",
        "station_name": "IAM",
        "description": "Manager IAM roles",
        "link": "/iam/roles/list",
        "icon": "iam_role_icon"
    },
    "policy": {
        "display": "Policy",
        "station_name": "IAM",
        "description": "Manager IAM policies",
        "link": "/iam/policies/list",
        "icon": "iam_policy_icon"
    },
    "sso": {
        "display": "SSO",
        "station_name": "IAM",
        "description": "Manager IAM sso",
        "link": "/iam/sso",
        "icon": "iam_sso_icon"
    }
};

type ServiceListItemProp = {
    service: Service;
    isFocused: boolean;
    onMouseEnter: () => void;
}

const ServiceListItem = ({ service, isFocused, onMouseEnter }: ServiceListItemProp) => {
    return (
        <a
            href={service.link}
            key={service.station_name}
            className={`flex flex-row items-center rounded-lg justify-between p-3 transition-colors outline-none
                ${isFocused ? 'bg-[hsl(var(--accent))]' : 'hover:bg-[hsl(var(--accent))]'}`}
            onMouseEnter={onMouseEnter}
        >
            <div className='flex items-center flex-1'>
                <div className="flex-shrink-0">
                    {icons[service.icon] ? icons[service.icon] : icons["unknown"]}
                </div>
                <div className="ml-3">
                    <h3 className={`font-bold ${isFocused ? 'text-primary' : 'text-gray-900 dark:text-white'}`}>
                        {service.display}
                    </h3>
                    <p className={`text-sm ${isFocused ? 'text-primary' : 'text-gray-500 dark:text-white'}`}>
                        {service.description}
                    </p>
                </div>
            </div>
            <div>
                <h3 className={`font-bold ${isFocused ? 'text-primary' : 'text-gray-500 dark:text-white'}`}>
                    {service.station_name}
                </h3>
            </div>
        </a>
    )
}

const ServiceSearch: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServices, setFilteredServices] = useState<Service[]>([]);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);

    const servicesArray = useMemo(() => Object.keys(services), [services]);
    const inputRef = useRef<HTMLInputElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target as Node) &&
                inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setIsPopoverVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle input change and filter services
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);

        if (value) {
            const searchLower = value.toLowerCase();
            const matches = servicesArray.filter(service =>
                service.toLowerCase().includes(value.toLowerCase())
            )
            .sort((a, b) => {
                const displayA = services[a].display.toLowerCase();
                const displayB = services[b].display.toLowerCase();
    
                // Find the position of the search term in each display
                const positionA = displayA.indexOf(searchLower);
                const positionB = displayB.indexOf(searchLower);
    
                // Sort by position: earlier occurrences come first
                if (positionA !== positionB) {
                    return positionA - positionB;
                }
    
                // If positions are the same, sort alphabetically
                return displayA.localeCompare(displayB);
            });
            setFilteredServices(matches.map(service => services[service]));
            if (matches.length) {
                setFocusedIndex(0)
            }
            setIsPopoverVisible(true);
        } else {
            setFilteredServices([]);
            setIsPopoverVisible(false);
        }
    };

    const handleServiceClick = (serviceName: string) => {
        setSearchTerm(serviceName);
        setIsPopoverVisible(false);
    };

    const onClearSearch = () => {
        setSearchTerm("");
        setIsPopoverVisible(false);
        setFocusedIndex(-1);
    };

    // Keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "m" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                if (inputRef.current) inputRef.current.focus();
            }

            if (isPopoverVisible && filteredServices.length > 0) {
                if (e.key === "ArrowDown") {
                    e.preventDefault();
                    setFocusedIndex(prev => (prev + 1) % filteredServices.length);
                } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    setFocusedIndex(prev =>
                        prev <= 0 ? filteredServices.length - 1 : prev - 1
                    );
                } else if (e.key === "Enter" && focusedIndex >= 0) {
                    e.preventDefault();
                    window.location.href = filteredServices[focusedIndex].link;
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [isPopoverVisible, filteredServices.length, focusedIndex]);

    return (
        <div className="relative w-96 mx-auto">
            <div className="relative">
                <Search className="absolute left-2 top-[0.45rem] h-4 w-4 text-muted-foreground" />
                <Input
                    ref={inputRef}
                    type="text"
                    placeholder="Search Defense Station services(⌘M)..."
                    value={searchTerm}
                    onChange={handleInputChange}
                    className='text-sm border rounded bg-[hsl(var(--background))] dark:border-gray-300 pl-8 h-8 pr-8'
                />
                {searchTerm &&
                    <X
                        onClick={onClearSearch}
                        className="cursor-pointer absolute right-2 top-[0.45rem] h-4 w-4 text-muted-foreground"
                    />
                }
            </div>
            {isPopoverVisible && (
                <div ref={popoverRef}>
                    <Card className="absolute left-0 right-0 mt-2 rounded-md shadow-lg z-10 p-0">
                        <CardContent className='p-0 bg-[hsl(var(--background))]'>
                            <CardHeader className='p-3'>
                                <CardTitle>
                                    Search results
                                </CardTitle>
                            </CardHeader>
                            <Separator orientation='horizontal' />
                            <ScrollArea className="h-72 max-h-72 w-full rounded-md p-2 pr-4 bg-[hsl(var(--background))]">
                                <div className="space-y-2 divide-y-2">
                                    {filteredServices.length > 0 ? (
                                        filteredServices.map((service, index) => (
                                            <ServiceListItem
                                                key={service.display}
                                                service={service}
                                                isFocused={index === focusedIndex}
                                                onMouseEnter={() => setFocusedIndex(index)}
                                            />
                                        ))
                                    ) : (
                                        <li className="px-4 py-2">No results found</li>
                                    )}
                                </div>
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default ServiceSearch;