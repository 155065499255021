import * as React from "react"
import { ChevronsUpDown, Plus } from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from "@/components/ui/sidebar"
import { useIsMobile } from "@/hooks/use-mobile"
import { Button } from "./ui/button"
import { cn } from "@/lib/utils"
import { navigateToUrl } from "single-spa"

function getServiceName(url: string): string {
  // Create a new URL object to parse the URL
  const parsedUrl = new URL(url);

  // Split the path by '/' and get the second-to-last part, which is the service name
  const pathSegments = parsedUrl.pathname.split('/');
  const serviceName = pathSegments[1];

  return serviceName;
}

export function TeamSwitcher({
  services,
}: {
  services: {
    name: string
    logo: React.ElementType
    plan: string
    link: string
    commandKey: string
  }[]
}) {
  const currentServiceName = React.useMemo(() => getServiceName(window.location.toString()), [window.location])
  const [activeTeam, setActiveTeam] = React.useState(services[0])
  const isMobile = useIsMobile();
  React.useEffect(() => {
    console.log({ currentServiceName })
    if (currentServiceName) {
      setActiveTeam(services.find(service => service.link == currentServiceName));
    }
  }, [currentServiceName])

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      const service = services.find(service => service.commandKey == e.key);
      if (service && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        
        if (service) {
          setActiveTeam(service)
          navigateToUrl("/" + service.link);
        }
      }
    }

    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div
              className={cn(
                "flex h-8 min-w-0 -translate-x-px items-center gap-2 overflow-hidden rounded-md px-2 text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground",
                "data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground",
                "group-data-[collapsible=icon]:hidden", "data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground hover:bg-sidebar-accent",
                "cursor-pointer bg-gray-200 dark:bg-slate-700")}
            >
              {/* <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                  <activeTeam.logo className="size-4" />
                </div> */}
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {activeTeam?.name}
                </span>
                {/* <span className="truncate text-xs">{activeTeam.plan}</span> */}
              </div>
              <ChevronsUpDown className="ml-auto" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[200px] min-w-[200px] rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "bottom"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Services
            </DropdownMenuLabel>
            {services.map((service, index) => (
              <DropdownMenuItem
                key={service.name}
                onClick={() => {
                  setActiveTeam(service)
                  navigateToUrl("/" + service.link);
                }}
                className="gap-2 p-2"
              >
                <div className="flex size-6 items-center justify-center rounded-sm border">
                  <service.logo className="size-4 shrink-0" />
                </div>
                {service.name}
                <DropdownMenuShortcut>⌘{service.commandKey}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            {/* <DropdownMenuItem className="gap-2 p-2">
                <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                  <Plus className="size-4" />
                </div>
                <div className="font-medium text-muted-foreground">Add team</div>
              </DropdownMenuItem> */}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
