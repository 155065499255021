import React, { useState } from 'react'
import ScreenCapture from '../ScreenCapture'
import { Button } from '../ui/button'
import { Bug } from 'lucide-react'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetClose,
} from "@/components/ui/sheet"
import BugReportForm, { BugFormData } from '../BugReportForm'
import { User } from '@/ds-apis'

type Props = {
    user: User;
}


export default function BugReport({ user }: Props) {
    const [isOpen, setIsOpen] = useState(false)
    const [pausedReportState, setPausedReportState] = useState<BugFormData>()

    const createFileFromDataURL = (dataURL: string, filename: string): File => {
        const binaryData = atob(dataURL.split(",")[1])
        const uint8Array = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i)
        }
        return new File(
            [new Blob([uint8Array], { type: dataURL.split(",")[0].split(":")[1].split(";")[0] })],
            filename
        )
    }


    const handleScreenCapture = (screenCapture: string) => {
        const images = pausedReportState?.images?.length ? pausedReportState.images : []
        const file = createFileFromDataURL(screenCapture, `screenshot-${images.length}`)
        images.push(file)
        console.log(images)
        setPausedReportState({ ...pausedReportState, images })
        setIsOpen(true)
    }

    return (
        <ScreenCapture onEndCapture={handleScreenCapture}>
            {({ onStartCapture }) => (<>
                <div className="flex items-center space-x-2">
                    <Button onClick={() => setIsOpen(true)} size={"icon"} variant={"ghost"}>
                        <Bug className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
                    </Button>
                </div>

                <Sheet open={isOpen} onOpenChange={setIsOpen}>
                    <SheetContent className="min-w-[500px]">
                        <SheetHeader>
                            <SheetTitle>Report Bug</SheetTitle>
                            <SheetClose />
                        </SheetHeader>
                        <div className="mt-4 max-h-screen overflow-auto pb-28">
                            <React.Suspense fallback={<p>Loading...</p>}>
                                <BugReportForm initialState={pausedReportState} user={user} onSuccess={() => null} onStartCapture={(data: BugFormData) => {
                                    console.log("On capture")
                                    setPausedReportState({ ...pausedReportState, ...data })
                                    setIsOpen(false)
                                    onStartCapture()
                                }} onError={(error) => null} />
                            </React.Suspense>
                        </div>
                    </SheetContent>
                </Sheet>
            </>
            )}
        </ScreenCapture>
    )
}
