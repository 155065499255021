import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Camera, Plus, X } from "lucide-react";
import { 
  Card,
  CardContent
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

// Define the form schema using zod
const formSchema = z.object({
  title: z.string().min(3, "Title must be at least 3 characters").max(255, "Title must not exceed 255 characters"),
  description: z.string().min(3, "Description must be at least 3 characters").max(1000, "Description must not exceed 1000 characters"),
});

// TypeScript interfaces
interface BugReportFormProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
  onStartCapture?: (data: BugFormData) => void;
  initialState?: BugFormData;
  user?: {
    accountId: string;
  };
}

export interface BugFormData {
  title: string;
  description: string;
  images?: File[];
}

const ImagePreview: React.FC<{ image: File; onRemove: () => void }> = ({ image, onRemove }) => {
  const imageUrl = URL.createObjectURL(image);

  return (
    <div className="relative w-[120px] h-[120px] rounded-md border border-gray-200">
      <img 
        src={imageUrl} 
        alt="Preview" 
        className="w-full h-full object-fill rounded-md"
      />
      <Button
        variant="ghost"
        size="icon"
        type="button"
        className="absolute top-1 right-1 h-6 w-6"
        onClick={e => {
          e.stopPropagation();
          onRemove()
        }}
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
};

const BugReportForm: React.FC<BugReportFormProps> = ({
  onSuccess = () => {},
  onError = () => {},
  onStartCapture = () => {},
  initialState,
  user,
}) => {
  const [images, setImages] = useState<File[]>([]);

  // Initialize form with react-hook-form and zod resolver
  const form = useForm<BugFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: `Account ID: ${user?.accountId}\nUser Agent: ${navigator.userAgent}\n\n**Describe the bug**\n<what's going wrong!?>\n\n\n\n\n**To Reproduce**\n<clear steps to reproduce are super helpful!`,
    },
  });

  // Update form when initialState changes
  useEffect(() => {
    if (initialState) {
      form.reset(initialState);
      if (initialState.images) {
        setImages(initialState.images);
      }
    }
  }, [initialState, form]);

  // Handle form submission
  const onSubmit = async (data: BugFormData) => {
    try {
      // Include images in the form data
      const formData = { ...data, images };
      await onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  // Handle file drops
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg']
    },
    onDrop: useCallback((acceptedFiles: File[]) => {
      setImages(prev => [...prev, ...acceptedFiles]);
    }, [])
  });

  // Remove image handler
  const handleRemoveImage = (index: number) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Title description will be here.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea 
                  {...field} 
                  rows={25}
                  className="resize-none"
                />
              </FormControl>
              <FormDescription>
                Description description will be here.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-4">
          <Card 
            className="cursor-pointer"
            onClick={() => onStartCapture({ ...form.getValues(), images })}
          >
            <CardContent className="flex flex-col items-center justify-center p-6">
              <Camera className="h-8 w-8" />
              <span className="mt-2">Take Screenshot</span>
            </CardContent>
          </Card>

          <Card {...getRootProps()} className="cursor-pointer">
            <CardContent className="flex flex-col items-center justify-center p-6">
              <input {...getInputProps()} />
              <Plus className="h-8 w-8" />
              <span className="mt-2">
                {isDragActive ? "Drop the files here" : "Drag 'n' drop images"}
              </span>
            </CardContent>
          </Card>
        </div>

        {images?.length?<div className="grid grid-cols-3 gap-4">
          {images.map((image, index) => (
            <ImagePreview
              key={index}
              image={image}
              onRemove={() => handleRemoveImage(index)}
            />
          ))}
        </div>:null}

        <Button className="w-full" type="submit" disabled={form.formState.isSubmitting}>
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default BugReportForm;