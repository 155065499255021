import React from 'react'
import { Button } from '@/components/ui/button'
import { useTheme } from '@/components/theme-provider'
import { Moon, Sun } from 'lucide-react';

export default function ThemeSwitcher() {
    const { theme, setTheme } = useTheme();
    return (
        <Button role='group' onClick={() => setTheme(theme == "light" ? "dark" : "light")} size={"icon"} variant={"ghost"}>
            {theme == "dark" ?
                <Sun className="h-[1.2rem] w-[1.2rem]" />
                :
                <Moon className="absolute h-[1.2rem] w-[1.2rem]" />}
        </Button>
    )
}
