import React, { useState, useEffect, useMemo } from "react";
import { User } from "@/ds-apis";
import { Button } from "@/components/ui/button"
import { Suspense } from "react";
import Navbar from "./components/navbar/Navbar";
import ServicesListDropdown from "./components/ServicesListDropdown";
const RoleSwitchingDialog = React.lazy(() => import("./components/RoleSwitchingDialog"));
import { Toaster } from "@/components/ui/toaster"
import { ThemeProvider } from "./components/theme-provider";
import { Separator } from "@/components/ui/separator";
import ThemeSwitcher from "@/components/theme-switcher";
import "./index.css";
import ServicesNavbar from "./components/navbar/ServicesSearchBar";
import BugReport from "./components/navbar/BugReport";

// Import the lazy-loaded UserProfile component
const UserProfile = React.lazy(() => import("./components/navbar/UserProfile"));

interface RootProps {
  type: string;
}


export default function Root({ type }: RootProps) {
  const [user, setUser] = useState<User>();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const isLoggedIn = await import("@defense-station/auth").then(
        module => module.isLoggedIn()
      );
      setAuthenticated(isLoggedIn);

      if (isLoggedIn) {
        const { userClient } = await import("@/grpc/client");
        const { user } = await userClient.getAuthInfo({});
        setUser(user);
      }
    };

    checkAuth();
  }, []);

  const onClickSignIn = async () => {
    const { navigateToUrl } = await import("single-spa");
    navigateToUrl("/login");
  };

  const isDsPlus = useMemo(() => type === "ds-plus", [type]);

  return (
    <>
      <ThemeProvider defaultTheme="system" storageKey="ui-theme">
        <Navbar>
          <Navbar.Brand>
            <a
              href={
                isDsPlus
                  ? `https://app.${process.env.DS_PLUS_DOMAIN}/`
                  : `https://app.${process.env.DOMAIN}/`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <img
                className="h-8 w-auto block dark:hidden"
                src={require("./assets/logo-dark.png")}
                alt="Logo"
              />
              <img
                className="h-8 w-auto hidden dark:block"
                src={require("./assets/logo.png")}
                alt="Logo"
              />
            </a>
          </Navbar.Brand>
          <Navbar.Links>
            <ServicesListDropdown />
            <ServicesNavbar />
          </Navbar.Links>
          <Navbar.UserProfile>
            {authenticated ? (
              <Suspense fallback={<div className="w-8 h-8 animate-pulse bg-gray-200 rounded-full" />}>
                <div className="flex gap-1 justify-center items-center">
                  <RoleSwitchingDialog />
                  <Separator className="h-6" orientation="vertical" />
                  {user && <BugReport user={user} />}
                  <ThemeSwitcher />
                  <Separator className="h-6 mr-1" orientation="vertical" />
                  <UserProfile
                    key={location.href}
                    user={user}
                    locationPath={location}
                    name={`${user?.firstName} ${user?.lastName}`}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    avatarUrl=""
                    email={user?.email}
                    type={type}
                  />

                </div>
              </Suspense>
            ) : (
              <Button onClick={onClickSignIn} variant="default">
                Sign in
              </Button>
            )}
          </Navbar.UserProfile>
        </Navbar>
        <Toaster />
      </ThemeProvider>
    </>
  );
}