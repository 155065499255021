import * as React from "react"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { AudioWaveform, File, FolderSync, GalleryVerticalEnd, Grid3x3Icon, LayoutGrid, Network, ShieldCheck } from "lucide-react"
import { navigateToUrl } from "single-spa"
import { TeamSwitcher } from "./team-switcher"


// This is sample data.
const services = [
    {
        name: "Policy Station",
        link: "policy",
        logo: File,
        plan: "Defense Station",
        commandKey: '1',
    },
    {
        name: "IAM Station",
        link: "iam",
        logo: ShieldCheck,
        plan: "Defense Station",
        commandKey: '2',
    },
    {
        name: "Sypher",
        link: "sypher",
        logo: FolderSync,
        plan: "Defense Station",
        commandKey: '3',
    },
    {
        name: "Asset Station",
        link: "asset-station",
        logo: Network,
        plan: "Defense Station",
        commandKey: '4',
    },
]


function getServiceName(url: string): string {
    // Create a new URL object to parse the URL
    const parsedUrl = new URL(url);

    // Split the path by '/' and get the second-to-last part, which is the service name
    const pathSegments = parsedUrl.pathname.split('/');
    const serviceName = pathSegments[pathSegments.length - 2];

    return serviceName;
}

export default function ServicesListDropdown() {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState("")

   

    const currentServiceName = React.useMemo(() => getServiceName(window.location.toString()), [window.location])

    React.useEffect(() => {
        console.log({ currentServiceName })
        if (currentServiceName) {
            setValue(currentServiceName);
        }
    }, [currentServiceName])

    return (
        <div className="max-w-32 w-32">
            <TeamSwitcher services={services} />
        </div>);
}
