// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    padding: 0;
    overflow-x: hidden;
  }
  
  .crosshairs {
    position: fixed;
    width: 100%;
    z-index: 2147483645;
  }
  
  .crosshairs.hidden {
    display: none;
  }
  
  .crosshairs::before,
  .crosshairs::after {
    content: "";
    position: absolute;
  }
  
  .crosshairs::before {
    height: 24px;
    width: 2px;
    background: #000;
    top: -11px;
  }
  
  .crosshairs::after {
    width: 24px;
    height: 2px;
    background: #000;
    left: -11px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .overlay.highlighting {
    background: none;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
  }
  
  .crosshairs,
  .crosshairs:before,
  .crosshairs:after,
  .overlay,
  .overlay:before,
  .overlay:after {
    box-sizing: border-box;
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/screen-capture.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;;IAEE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;EACtC;;EAEA;IACE,gBAAgB;IAChB,gCAAgC;IAChC,mBAAmB;EACrB;;EAEA;;;;;;IAME,sBAAsB;EACxB","sourcesContent":["body {\n    padding: 0;\n    overflow-x: hidden;\n  }\n  \n  .crosshairs {\n    position: fixed;\n    width: 100%;\n    z-index: 2147483645;\n  }\n  \n  .crosshairs.hidden {\n    display: none;\n  }\n  \n  .crosshairs::before,\n  .crosshairs::after {\n    content: \"\";\n    position: absolute;\n  }\n  \n  .crosshairs::before {\n    height: 24px;\n    width: 2px;\n    background: #000;\n    top: -11px;\n  }\n  \n  .crosshairs::after {\n    width: 24px;\n    height: 2px;\n    background: #000;\n    left: -11px;\n  }\n  \n  .overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n  \n  .overlay.highlighting {\n    background: none;\n    border-color: rgba(0, 0, 0, 0.5);\n    border-style: solid;\n  }\n  \n  .crosshairs,\n  .crosshairs:before,\n  .crosshairs:after,\n  .overlay,\n  .overlay:before,\n  .overlay:after {\n    box-sizing: border-box;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
